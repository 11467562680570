import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Img from 'gatsby-image';
import Moment from 'react-moment';
import Fade from 'react-reveal/Fade';
import { graphql, useStaticQuery } from 'gatsby';

import offers from '../../data/breakaway.json';
import breaksHopton from '../../data/breaks-hopton.json';
import breaksFiveLakes from '../../data/breaks-five-lakes.json';
import { Button } from 'react-bootstrap';
const slugify = require('slugify');

const BreaksGrid = props => {
  const formatNumber = num => {
    return parseFloat(num) % 1 ? parseFloat(num).toFixed(2) : parseInt(num);
  };

  const data = useStaticQuery(graphql`
    query {
      AW1: file(relativePath: { eq: "all-inclusive-break-headers/AW1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW2: file(relativePath: { eq: "all-inclusive-break-headers/AW2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW3: file(relativePath: { eq: "all-inclusive-break-headers/AW3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW4: file(relativePath: { eq: "all-inclusive-break-headers/AW4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW5: file(relativePath: { eq: "all-inclusive-break-headers/AW5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW6: file(relativePath: { eq: "all-inclusive-break-headers/AW6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW7: file(relativePath: { eq: "all-inclusive-break-headers/AW7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW8: file(relativePath: { eq: "all-inclusive-break-headers/AW8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW9: file(relativePath: { eq: "all-inclusive-break-headers/AW9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW10: file(relativePath: { eq: "all-inclusive-break-headers/AW10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW11: file(relativePath: { eq: "all-inclusive-break-headers/AW11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW12: file(relativePath: { eq: "all-inclusive-break-headers/AW12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW13: file(relativePath: { eq: "all-inclusive-break-headers/AW13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW14: file(relativePath: { eq: "all-inclusive-break-headers/AW14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW15: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW16: file(relativePath: { eq: "all-inclusive-break-headers/AW16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW17: file(relativePath: { eq: "all-inclusive-break-headers/AW17.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW18: file(relativePath: { eq: "all-inclusive-break-headers/AW18.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW19: file(relativePath: { eq: "all-inclusive-break-headers/AW19.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW20: file(relativePath: { eq: "all-inclusive-break-headers/AW20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      AW25: file(relativePath: { eq: "all-inclusive-break-headers/AW25.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F1: file(relativePath: { eq: "all-inclusive-break-headers/F1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F2: file(relativePath: { eq: "all-inclusive-break-headers/F2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F2Alt: file(relativePath: { eq: "all-inclusive-break-headers/F2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F3: file(relativePath: { eq: "all-inclusive-break-headers/F3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F4: file(relativePath: { eq: "all-inclusive-break-headers/F4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F4Alt: file(relativePath: { eq: "all-inclusive-break-headers/F4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F5: file(relativePath: { eq: "all-inclusive-break-headers/F5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F6: file(relativePath: { eq: "all-inclusive-break-headers/F6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F7: file(relativePath: { eq: "all-inclusive-break-headers/F7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F8: file(relativePath: { eq: "all-inclusive-break-headers/F8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F9: file(relativePath: { eq: "all-inclusive-break-headers/F9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      F10: file(relativePath: { eq: "all-inclusive-break-headers/F10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      HT1: file(relativePath: { eq: "all-inclusive-break-headers/HT1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      HT2: file(relativePath: { eq: "all-inclusive-break-headers/HT2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      HT3: file(relativePath: { eq: "all-inclusive-break-headers/HT3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      HT4: file(relativePath: { eq: "all-inclusive-break-headers/HT4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS1: file(relativePath: { eq: "all-inclusive-break-headers/SS1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS2: file(relativePath: { eq: "all-inclusive-break-headers/SS2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS3: file(relativePath: { eq: "all-inclusive-break-headers/SS3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS4: file(relativePath: { eq: "all-inclusive-break-headers/SS4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS5: file(relativePath: { eq: "all-inclusive-break-headers/SS5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS6: file(relativePath: { eq: "all-inclusive-break-headers/SS6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS7: file(relativePath: { eq: "all-inclusive-break-headers/SS7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS8: file(relativePath: { eq: "all-inclusive-break-headers/SS8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS9: file(relativePath: { eq: "all-inclusive-break-headers/SS9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS10: file(relativePath: { eq: "all-inclusive-break-headers/SS10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS11: file(relativePath: { eq: "all-inclusive-break-headers/SS11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS12: file(relativePath: { eq: "all-inclusive-break-headers/SS12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS13: file(relativePath: { eq: "all-inclusive-break-headers/SS13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS14: file(relativePath: { eq: "all-inclusive-break-headers/SS14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS15: file(relativePath: { eq: "all-inclusive-break-headers/SS15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS16: file(relativePath: { eq: "all-inclusive-break-headers/SS16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS17: file(relativePath: { eq: "all-inclusive-break-headers/SS17.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS18: file(relativePath: { eq: "all-inclusive-break-headers/SS18.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS19: file(relativePath: { eq: "all-inclusive-break-headers/SS19.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS20: file(relativePath: { eq: "all-inclusive-break-headers/SS20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS21: file(relativePath: { eq: "all-inclusive-break-headers/SS21.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS22: file(relativePath: { eq: "all-inclusive-break-headers/SS22.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS23: file(relativePath: { eq: "all-inclusive-break-headers/SS23.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS24: file(relativePath: { eq: "all-inclusive-break-headers/SS24.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SS25: file(relativePath: { eq: "all-inclusive-break-headers/SS25.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB1: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB2: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB3: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB4: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB5: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB6: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB7: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      WB8: file(relativePath: { eq: "all-inclusive-break-headers/AW15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const breakCategory = (category, subCategory, family) => {
    if (subCategory === 'Festive') {
      return 'festive';
    } else if (family && subCategory !== 'Festive') {
      return 'family';
    } else {
      if (category === 'Weekend') {
        return 'weekend';
      } else {
        return 'midweek';
      }
    }
  };

  return (
    <Row>
      <div
        className="mb-3"
        style={{ textAlign: 'center', width: '100%', color: '#fff' }}
      >
        <h1>Your Latest Breakaway Club Offers</h1>
      </div>
      {offers.map(shortBreak => {
        const json =
          shortBreak.venue.toLowerCase() === 'five lakes'
            ? breaksFiveLakes
            : breaksHopton;
        const thisBreak = json.filter(a => a.id === shortBreak.id)[0];
        const link = shortBreak.online
          ? shortBreak.venue === 'Five Lakes'
            ? `https://www.pottersresorts.com/five-lakes/short-breaks/${breakCategory(
                thisBreak.category,
                thisBreak.subCategory,
                thisBreak.family
              )}/${slugify(thisBreak.title).toLowerCase()}/?utm_term=${
                shortBreak.code
              }`
            : `https://www.pottersresorts.com/hopton-on-sea/short-breaks/${breakCategory(
                thisBreak.category,
                thisBreak.subCategory,
                thisBreak.family
              )}/${slugify(thisBreak.title).toLowerCase()}/?utm_term=${
                shortBreak.code
              }`
          : '';

        return (
          <Col xs={12} key={shortBreak.title}>
            <Fade up>
              <div className="breakaway-break">
                {shortBreak.taster ? (
                  <div className="breakaway-break-content">
                    <h5>
                      Potters Resorts{' '}
                      {shortBreak.venue === 'Five Lakes'
                        ? 'Five Lakes'
                        : 'Hopton-on-Sea'}
                    </h5>
                    <div>
                      <span className="breakaway-date">
                        {thisBreak.title.split('-')[0]}
                      </span>
                      <br />
                      <span className="breakaway-breaktype">
                        {thisBreak.title.split('-')[1]}
                      </span>
                      <br />
                      <span
                        style={{
                          color: '#0068A2',
                          fontSize: '26px',
                          fontWeight: 700
                        }}
                      >
                        £{thisBreak.adultPrice} Per Person
                      </span>
                      <br />
                      <span
                        style={{
                          color: '#DD3444',
                          fontSize: '30px',
                          fontWeight: 700
                        }}
                      >
                        Only £{thisBreak.adultPrice * 2} per couple!
                      </span>
                      <br />
                      <span className="breakaway-breaktype">
                        {shortBreak.codeText}
                      </span>
                      <br />
                      <br />
                    </div>
                    {thisBreak.soldOut ? (
                      <img
                        className="breakaway-break-img"
                        src="https://resort2-cdn.s3.eu-west-2.amazonaws.com/resort2-images/landers/sold-out-image-breakaway.jpg"
                        alt="Sold Out"
                      />
                    ) : (
                      <>
                        {shortBreak.venue.toLowerCase() === 'five lakes' ? (
                          <img
                            className="breakaway-break-img"
                            src="https://resort2-cdn.s3.eu-west-2.amazonaws.com/resort2-images/five-lakes/page-headers/five-lakes-header-front-aerial-logo.jpg"
                          />
                        ) : (
                          <a href={link}>
                            <Img
                              className="breakaway-break-img"
                              fluid={
                                data[thisBreak.image].childImageSharp.fluid
                              }
                            />
                          </a>
                        )}
                      </>
                    )}
                    <br />
                    {!shortBreak.soldOut && (
                      <>
                        <span style={{ color: '#006AA8', fontSize: '20px' }}>
                          Use code
                        </span>
                        <br />
                        <span
                          style={{
                            color: '#DD3444',
                            fontSize: '30px',
                            fontWeight: 700
                          }}
                        >
                          {shortBreak.code}
                        </span>
                        <br />
                        <span style={{ color: '#006AA8', fontSize: '20px' }}>
                          at time of booking to receive your{' '}
                          <strong>exclusive</strong> Breakaway Club Price
                        </span>
                        <br />
                        <br />
                        <br />
                        <span style={{ color: '#006AA8', fontSize: '30px' }}>
                          CALL US NOW on{' '}
                          <strong>
                            <a href="tel:03333207466">0333 3207 466</a>
                          </strong>
                        </span>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="breakaway-break-content">
                    <h5>
                      Potters Resorts{' '}
                      {shortBreak.venue === 'Five Lakes'
                        ? 'Five Lakes'
                        : 'Hopton-on-Sea'}
                    </h5>
                    <div>
                      <span className="breakaway-date">
                        {/* <Moment format="dddd MMMM YY" withTitle> */}
                        {thisBreak.title.split('-')[0]}
                        {/* </Moment>                         */}
                      </span>
                      <br />
                      <span className="breakaway-breaktype">
                        {thisBreak.title.split('-')[1]}
                      </span>
                      <br />
                      <span>
                        <strike style={{ color: '#A8A8A8', fontSize: '20px' }}>
                          £{thisBreak.adultPrice} Per Person
                        </strike>
                      </span>
                      <br />
                      <span
                        style={{
                          color: '#0068A2',
                          fontSize: '26px',
                          fontWeight: 700
                        }}
                      >
                        £{thisBreak.adultPrice - shortBreak.save} Per Person
                      </span>
                      <br />
                      <span
                        style={{
                          color: '#DD3444',
                          fontSize: '30px',
                          fontWeight: 700
                        }}
                      >
                        Save £{shortBreak.save} per person!
                      </span>
                      <br />
                      <span className="breakaway-breaktype">
                        {shortBreak.codeText}
                      </span>
                      <br />
                      <br />
                    </div>
                    {shortBreak.soldOut ? (
                      <img
                        className="breakaway-break-img"
                        src="https://resort2-cdn.s3.eu-west-2.amazonaws.com/resort2-images/landers/sold-out-image-breakaway.jpg"
                        alt="Sold Out"
                      />
                    ) : (
                      <>
                        {shortBreak.venue.toLowerCase() === 'five lakes' ? (
                          <img
                            className="breakaway-break-img"
                            src={
                              thisBreak.title ===
                              '6th January 2023 - 3 Night Weekend'
                                ? 'https://resort2-cdn.s3.eu-west-2.amazonaws.com/resort2-images/potters-resorts/full-width-headers/mark-brewer-header-full-width.jpg'
                                : 'https://resort2-cdn.s3.eu-west-2.amazonaws.com/resort2-images/five-lakes/page-headers/five-lakes-header-front-aerial-logo.jpg'
                            }
                          />
                        ) : (
                          <a href={link}>
                            <Img
                              className="breakaway-break-img"
                              fluid={
                                data[thisBreak.image].childImageSharp.fluid
                              }
                            />
                          </a>
                        )}
                      </>
                    )}
                    <br />
                    {!shortBreak.soldOut && (
                      <>
                        <span style={{ color: '#006AA8', fontSize: '20px' }}>
                          Use code
                        </span>
                        <br />
                        <span
                          style={{
                            color: '#DD3444',
                            fontSize: '30px',
                            fontWeight: 700
                          }}
                        >
                          {shortBreak.code}
                        </span>
                        <br />
                        <span style={{ color: '#006AA8', fontSize: '20px' }}>
                          at time of booking to receive your{' '}
                          <strong>exclusive</strong> Breakaway Club Price
                        </span>
                        <br />
                        <br />
                        {shortBreak.online ? (
                          <>
                            <a href={link}>
                              <Button>Book Now</Button>
                            </a>
                            <br />
                            <br />
                            <span
                              style={{ color: '#006AA8', fontSize: '20px' }}
                            >
                              Or call us on{' '}
                              <strong>
                                <a href="tel:03333207466">0333 3207 466</a>
                              </strong>
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ color: '#006AA8', fontSize: '30px' }}
                            >
                              CALL US NOW on{' '}
                              <strong>
                                <a href="tel:03333207466">0333 3207 466</a>
                              </strong>
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </Fade>
          </Col>
        );
      })}
      <div
        className="col-12 mb-5 mb-md-2"
        style={{ textAlign: 'center', color: '#fff', fontSize: '14px' }}
      >
        <p>
          All prices are based on Standard Bungalow accommodation at Potters
          Resorts Hopton-on-Sea and Premier accommodation at Potters Resorts
          Five Lakes.
        </p>
        <p>
          Supplements apply for Bungalow Plus and Hotel accommodation at
          Hopton-on-Sea and Plus, Deluxe and Suite accommodation at Five Lakes.
          Discount codes must be quoted at time of booking, discount cannot be
          applied once bookings have been confirmed. Discount is applicable on
          new bookings only, cannot be used in conjunction with any other offer,
          promotion or discount, and is subject to promotional rate
          availability. Discount not applicable on Group Bookings handled by our
          Group Sales Team. Potters Resorts reserves the right to change amend
          or withdraw offers at any time and without prior notice.
        </p>
      </div>
    </Row>
  );
};

export { BreaksGrid };
