import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import { FrontTitle } from '../components/page/FrontTitle';
import Container from 'react-bootstrap/Container';
import { BreaksGrid } from '../components/breaks/BreaksGrid';

const HomePage = () => {
  return (
    <DefaultLayout styles={{backgroundColor: '#006AA8'}}>
      <FrontTitle/>
      <Container>
        <main className="breakaway-content">
          <BreaksGrid/>
        </main>
      </Container>
    </DefaultLayout>
  );
};

export default HomePage;
