import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import Zoom from 'react-reveal/Zoom';
import Pulse from 'react-reveal/Pulse';
import { BottomSectionOne } from './sections/bottom/BottomSectionOne';


const FrontTitle = () => {
  return (
    <div>
      <div>
        <div className="breakaway-header-outer">
          <img style={{width: '100%'}} className="outer" src={`https://resort2-cdn.s3.eu-west-2.amazonaws.com/resort2-images/home/coast-header.jpg`}/>
        </div>
      </div>
    </div>
  );
};

export { FrontTitle };